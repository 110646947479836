import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"

const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 7" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 7</h1>
      <p>Endelig mandag!</p>
      <p>Takk for en herlig helg med deg mamma litjen (og pappa litjen)! Det var hyggelig! Og veldig spennende å se så mye lys ute på trilletur i går - jeg kunne bare ikke sove det bort!</p>
      <p>Håper du liker sminketingen du fikk i dag. Jeg aaaaner ikke hva den skal brukes til. Tihihihihiii!</p>
      <p>Hilsen Litjen</p>
    </div>
  </Layout>
)

export default LitjenLuke
